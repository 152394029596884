/* eslint-disable react/prop-types */
/* eslint-disable no-confusing-arrow */
import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import WidthWrapper from '../components/WidthWrapper';
import ExternalLink from '../components/ExternalLink';

const FooterWrapper = styled.div`
  padding-top: 52px;
  padding-bottom: 52px;
  user-select: none;
  display: flex;
  flex-direction: row;
  @media screen and (max-width: 900px) {
    justify-content: flex-start;
  }
`;

const ColumnHeading = styled.div`
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color:  ${props => props.white ? 'rgba(256,256, 256, 0.870588)' : 'rgba(0, 0, 0, 0.870588)'};
  text-transform: uppercase;
  margin-bottom: 4px;
`;

const Item = styled(Link)`
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color:  ${props => props.white ? 'rgba(256,256, 256, 0.6)' : 'rgba(0, 0, 0, 0.6)'};
  margin-top: 6px;
  cursor: pointer;
  text-decoration: none;
`;

const StyledExternalLink = styled(ExternalLink)`
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color:  ${props => props.white ? 'rgba(256,256, 256, 0.6)' : 'rgba(0, 0, 0, 0.6)'};
  margin-top: 6px;
  cursor: pointer;
  text-decoration: none;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 66px;
`;

const Container = styled.div`
  background: ${props => props.showBackground ? 'linear-gradient(137.8deg, rgb(0, 81, 240) 0%, rgb(0, 201, 240) 99.99%, rgb(0, 81, 240) 100%)' : 'none'};
  overflow: hidden;
  -webkit-backface-visibility: hidden;
  display: flex;
  flex-direction: column;
`;

const Footer = ({ showBackground }) => (
  <Container showBackground={showBackground}>
    <WidthWrapper>
      <FooterWrapper>
        <Column>
          <ColumnHeading white={showBackground}>
            Bidmatik
          </ColumnHeading>
          <Item to="/aboutus" white={showBackground}>
            About Us
          </Item>
          <Item to="termsOfUse" white={showBackground}>
            Terms
          </Item>
        </Column>
        <Column>
          <ColumnHeading white={showBackground}>
            LINKS
          </ColumnHeading>
          <Item to="/help" white={showBackground}>
            Help
          </Item>
          <Item to="/contactus" white={showBackground}>
            Contact Us
          </Item>
          <StyledExternalLink white={showBackground} target="_blank" href="https://www.youtube.com/channel/UCHqswzSt3ppqR4y8fN3CADg">
              YouTube
          </StyledExternalLink>
        </Column>
      </FooterWrapper>
    </WidthWrapper>
  </Container>
);

export default Footer;
