import styled from 'styled-components';

export default styled.div`
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  padding: 11px 25px 11px 25px;
  color: #FFFFFF;
  cursor: pointer;
  user-select: none;
  text-transform: uppercase;
  transition: box-shadow 400ms ease, transform 400ms ease;
  transform: scale(1, 1);
  &:hover {
    box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
    transition: box-shadow 200ms ease,;
  }
`;
