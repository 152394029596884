import styled from 'styled-components';

export default styled.div`
  @media screen and (min-width: 900px) {
    max-width: 942px;
    align-self: center;
    width: 100%;
  }
  @media screen and (max-width: 900px) {
    padding: 0 20px 0 20px;
  }
`;
