/* eslint-disable */
/* eslint-disable no-undef */
import React, { useState } from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import WidthWrapper from './WidthWrapper';
import Button from './Button';
import ExternalLink from './ExternalLink';
import logoPath from '../images/logo.png';
import hamburgerPath from '../images/hamburger.svg';

const Container = styled.div`
  display: flex;
  font-family: Roboto;
  background: #fff;
  justify-content: space-between;
  @media screen and (min-width: 900px) {
    flex-direction: row;
    margin-top: 21px;
    align-items: center;
  }
  @media screen and (max-width: 900px) {
    flex-direction: row;
    align-items: flex-end;
  }

  .active_link {
    text-decoration: underline;
  }
`;

const Logo = styled.img`
  width: 101px;
  height: 15px;
  cursor: pointer;
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  @media screen and (min-width: 900px) {
    align-items: center;
  }
  @media screen and (max-width: 900px) {
    align-items: flex-end;
    width: 200px;
    justify-content: space-between;
  }
`;

const NavLink = styled(Link)`
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 14px;
  color: #1b1240;
  text-decoration: none;
  @media screen and (min-width: 900px) {
    margin-right: 20px;
  }
  @media screen and (max-width: 900px) {
    font-size: 15px;
  }
  text-transform: uppercase;
`;

const SignupButton = styled(Button)`
  background: linear-gradient(
    137.8deg,
    #0051f0 0%,
    #00c9f0 99.99%,
    #0051f0 100%
  );
  @media screen and (min-width: 900px) {
    margin-left: 62px;
  }
`;

const HideOnDesktop = styled.div`
  @media screen and (min-width: 900px) {
    display: none;
  }
  @media screen and (max-width: 900px) {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }
`;

const HideOnMobile = styled.div`
  @media screen and (max-width: 900px) {
    display: none;
  }
`;

const Hamburger = styled.img`
  margin-left: 15px;
`;

const MenuRow = styled.div`
  display: flex;
  flex-direction: row;
`;

const Navigation = () => {
  const [isOpen, setIsOpen] = useState(false);
  const toggleIsOpen = () => setIsOpen(!isOpen);
  return (
    <WidthWrapper>
      <Container>
        <ExternalLink href="https://bidmatik.com">
          <Logo src={logoPath} />
        </ExternalLink>
        <HideOnDesktop>
          <MenuRow>
            {isOpen && (
              <ButtonWrapper>
                <NavLink to="/" activeClassName="active_link">Features</NavLink>
                <NavLink to="/pricing" activeClassName="active_link">Pricing</NavLink>
                <NavLink partiallyActive={true} to="/learn" activeClassName="active_link">Learn</NavLink>
              </ButtonWrapper>
            )}
            <Hamburger onClick={toggleIsOpen} src={hamburgerPath} />
          </MenuRow>
        </HideOnDesktop>
        <HideOnMobile>
          <ButtonWrapper>
            <NavLink to="/" activeClassName="active_link">Features</NavLink>
            <NavLink to="/pricing" activeClassName="active_link">Pricing</NavLink>
            <NavLink partiallyActive={true} to="/learn" activeClassName="active_link">Learn</NavLink>
            <ExternalLink href="https://app.bidmatik.com">
              <SignupButton>Signup / Login </SignupButton>
            </ExternalLink>
          </ButtonWrapper>
        </HideOnMobile>
      </Container>
    </WidthWrapper>
  );
};

export default Navigation;
